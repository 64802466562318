<script setup>
import { ref } from 'vue'
import axios from 'axios'

const form = ref(null)
const email = ref('')
const status = ref("ready")
const emailRules = [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ]
const valid = true

async function sendLoginEmail() {
  const formValidation = await form.value.validate()
  if (formValidation.valid) {
    let url = "/api/auth/" + email.value
    try {
      status.value = "processing"
      await axios.get(url)
      status.value = "success"
    }
    catch (ex) {
      console.log(ex)
      status.value = "error"
    }
  }
}

</script>


<template>
      <v-form 
      ref="form"
      @submit.prevent="sendLoginEmail" 
      v-model="valid">
        <v-text-field
          v-model="email"
          :rules="emailRules"
          label="Enter your email address to get a login link"
          required
          validate-on="blur"
          name="email"
        ></v-text-field>
        <v-btn
          :loading="status == 'processing'"
          :disabled="status == 'processing'"
          type="submit"
          color="success"
          class="me-4"
          size="large"
        >
        Get Login Link
        </v-btn>
      </v-form>
      <v-alert type="success" v-show="status=='success'" class="mt-5" text="Login email has been sent, please check your email to proceed. You can close this page."></v-alert>
      <v-alert type="error" v-show="status=='error'" class="mt-5" text="There was a problem, please check your email address and try again."></v-alert>
</template>


<style scoped>
</style>
