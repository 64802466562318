<script setup>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router';
import { store } from '@/store/store';
import axios from 'axios'
import EventItem from '@/components/EventItem.vue'
const upcoming = ref()
const past = ref()
const _ = require("lodash");
const dayjs = require('dayjs')
let loaded = ref(false)
const router = useRouter()

let eventsList = null

function goToEvent(evt) {
  store.currentEventName = evt.Name
  router.push({ name: 'Schedule', params: { eventId: evt.EventId } })
}

async function getEvents() {
  let url = "/api/events"
  try {
    if (eventsList == null) {
      let res = await axios.get(url)
      eventsList = res.data
    }
    loadEvents()
  }
  catch (ex) {
    if (ex.response?.status == 401)
      router.push('/')
    else
      console.log(ex)
  }
}

function loadEvents() {
  upcoming.value = eventsList.filter(x => dayjs(x.EndDate) >= dayjs())
  upcoming.value = _.orderBy(upcoming.value, ['EndDate'], ['asc'])

  past.value = eventsList.filter(x => dayjs(x.EndDate) < dayjs())
  past.value = _.orderBy(past.value, ['EndDate'], ['desc'])

  if (!(router.currentRoute.value.query.autoroute == '0')) {
    if (upcoming.value.length == 1) {
      goToEvent(upcoming.value[0])
    } else {
      let recentEvents = upcoming.value.filter(e => dayjs(e.EndDate) > dayjs().subtract(1, 'month'))
      if (recentEvents.length == 1) {
        goToEvent(recentEvents[0])
      }
    }
  }
  loaded.value = true
}


const hasFutureEvents = computed(() => {
  return upcoming.value?.length > 0
})

const hasPastEvents = computed(() => {
  return past.value?.length > 0
})
getEvents();
</script>


<template>
  <v-banner v-if="!loaded">Loading Events...</v-banner>
  <div v-else>
    <v-row>
      <div class="title">Upcoming Events</div>
    </v-row>
    <v-row v-if="hasFutureEvents">
      <EventItem v-for="evt in upcoming" :key="evt" :event="evt"></EventItem>
    </v-row>
    <p v-else class="msg-no-events">You do not currently have any upcoming events.</p>
    <div v-if="hasPastEvents">
      <v-row class="title">Past Events</v-row>
      <v-row>
        <EventItem v-for="evt in past" :key="evt" :event="evt"></EventItem>
      </v-row>
    </div>
    <v-row v-if="!hasFutureEvents && !hasPastEvents">
      <v-alert type="error">Your email is not associated with any events.</v-alert>
    </v-row>
  </div>
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  font-size: 1.2em;
}

.msg-no-events {
  margin: 20px;
}
</style>
