<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useRouter } from 'vue-router';
import { store } from '@/store/store';
import axios from 'axios'

const route = useRoute();
const router = useRouter();

async function logout() {
  let url = "/api/logout"
  try {
    await axios.post(url)
    store.currentEventName = null
    router.push('/')
  }
  catch (ex) {
    console.log(ex)
  }
}

let loggedIn = computed(() => {
  return route.meta.loggedIn
})

function goEvents() {
  store.currentEventName = null
  router.push("/events?autoroute=0")
}
</script>

<template>
  <v-app-bar class="volcor-header">
    <v-app-bar-title>
      {{ store.currentEventName ? store.currentEventName : "Volunteer Portal" }}
    </v-app-bar-title>
    <template v-slot:append>
      <v-menu v-if="loggedIn">
        <template v-slot:activator="{ props }">
          <v-btn icon="mdi-dots-vertical" v-bind="props"></v-btn>
        </template>
        <v-list>
          <v-list-item v-if="store.currentEventName != null" @click="goEvents()" title="All events"
            prepend-icon="mdi-arrow-left" />
          <v-list-item @click="logout()" title="Logout" prepend-icon="mdi-logout" />
        </v-list>
      </v-menu>
    </template>
  </v-app-bar>
</template>

<style scoped>
.volcor-header {
  background-color: #004d4d !important;
  color: white !important;
}
</style>