<!--eslint-disable-->
<script setup>

import { computed, defineProps } from 'vue';
import { dateUtils } from '@/util/dateUtil';
const dayjs = require('dayjs')

const props = defineProps({
    shift: Object
})

const isManual = computed(() => {
    return props.shift.Position == "Manual Entry"
})

const isFuture = computed(() => {
    return dayjs(props.shift.Start) > dayjs()
})

const statusLabels = {
    "future": "Upcoming",
    "now": "Happening Now",
    "past": "Pending Approval",
    "ok": "Approved",
    "warn": "Excused",
    "danger": "No Show"
}

const status = computed(() => {
    let ret = ""
    if (props.shift.Status == null) {
        ret = props.shift.timing
    } else {
        ret = props.shift.Status
    }
    return ret
})

const credits = computed(() => {
    let ret = 0
    if(props.shift.Status == null) {
        ret = props.shift.credits
    } else {
        ret = (props.shift.Status == "ok" ? props.shift.credits : 0)
    }
    return ret
})

</script>

<template>
    <v-row class="shift-item" :class="status">
        <v-col>
            <h3 v-if="!isManual">
                {{ dateUtils.shiftTime(shift.Start, shift.End) }}
            </h3>
            <div>
                <span class="dept-name"><strong>{{ shift.Department }}</strong></span>
                <br> {{ shift.Position }}
            </div>
        </v-col>
        <v-col class="text-right">
            <h4>{{ statusLabels[status] }}</h4>
           <div> {{ shift.Status == null ? "Worth" : "Earned" }} <b>{{ credits }}</b> credit{{credits != 1?"s":""}}</div>
           {{ shift.Weight != 1 ? `(${shift.Weight} credits/hour)` : "" }}
        </v-col>
    </v-row>
</template>

<style scoped>

.past {
    background-color: rgb(195, 199, 248);
}

.ok {
    background-color: rgb(168, 236, 168);
}

.warn {
    background-color: rgb(255, 235, 153);
}

.danger {
    background-color: rgb(255, 153, 153);
}


.shift-item {
    box-sizing: border-box;
    margin-top: 0;
    padding: 5px 10px;
}

.shift-item:not(:last-child) {
    border-bottom: 1px solid #ccc;
}

.dept-name {
    font-size: 1.1em;
}
</style>