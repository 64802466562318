<script setup>
import router from '@/router/routes';

function returnToLogin() {
    router.push("/")
}
</script>

<template>
    <v-alert type="error" class="mt-3" title="Login Failed" text="Your login link may have expired, please return to the login page and get another link."></v-alert>
    
    <v-btn
      type="submit"
      color="info"
      class="me-4 mt-5"
      size="large"
      @click="returnToLogin"
    >Go to Login Page</v-btn>
</template>

<style scoped>
</style>