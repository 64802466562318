<script setup>

import { defineProps } from 'vue';

import TextEventScheduleDayShift from '@/components/TextEventScheduleDayShift.vue'
defineProps(['item'])
const dayjs = require('dayjs')

function dayForDisplay(item) {
    return dayjs(item.Date).format("dddd")
}

function dateForDisplay(item) {
    return dayjs(item.Date).format("MMM DD")
}

</script>

<template>
    <v-col cols="12" md="8" class="offset-md-2">
        <v-row class="shift-day">
            <v-card style="width: 100%">
                <v-card-title class="title">
                    {{ item.Timing == 'today' ? 'Today' : `${dayForDisplay(item)}, ${dateForDisplay(item)}` }}
                </v-card-title>
                <v-card-text class="shift-content">
                    <TextEventScheduleDayShift v-for="shift in item.Shifts" :key="shift" :shift="shift"/>
                </v-card-text>
            </v-card>
        </v-row>
    </v-col>
</template>

<style scoped>

.shift-content {
    padding: 0px;
}

.title {
    font-weight: bold;
    background-color: #486574;
    padding: 2px 9px 2px 9px;
    color: white;
}

.shift-day {
    text-align: left;
    margin-bottom: 5px;
    border: 2px solid grey;
    border-radius: 5px;
}
</style>