const dayjs = require('dayjs')

export const dateUtils = {
    // formats a start and end date for events
    startEndDisplay: function startEndDisplay(startDte, endDte, alwaysIncludeTime = false) {
        const start = dayjs(startDte)
        const end = dayjs(endDte)

        let displayString = ""
        // if within one day, just show the date once and start/end time
        if (end.diff(start, 'hour') < 24) {
            displayString = start.format("MMM D, h:mm A") + " - " + end.format("h:mm A") + ", " + start.format("YYYY")
        }
        // if in the same month, show month once and start/end days, no time
        else if (start.format("MMM YYYY") === end.format("MMM YYYY")) {
            if (alwaysIncludeTime) {
                displayString = start.format("MMM D h:mm A") + " - " + end.format("D h:mm A") + ", " + start.format("YYYY")
            }
            else {
                displayString = start.format("MMM D") + " - " + end.format("D") + ", " + start.format("YYYY")
            }
        }
        // otherwise show start and end date month and year
        else {
            if (alwaysIncludeTime) {
                // Dec 23, 2022 10:00 AM - Jan 3, 2023 12:00 PM
                displayString = start.format("MMM D") + ", " + start.format("YYYY") + " " + start.format("h:mm A") + " - " + end.format("MMM D") + ", " + end.format("YYYY") + " " + end.format("h:mm A")
            }
            else {
                displayString = start.format("MMM D") + ", " + start.format("YYYY") + " - " + end.format("MMM D") + ", " + end.format("YYYY")
            }
        }

        return displayString
    },
    shiftTime: function shiftTime(startStr, endStr) {
        const start = dayjs(startStr)
        const end = dayjs(endStr)

        let display = ''
        if (start.format('a') == end.format('a')) {
            display = start.format("h:mm") + " - " + end.format("h:mm a")
        }
        else {
            display = start.format("h:mm a") + " - " + end.format("h:mm a")
        }

        if (end.diff(start, 'hours') > 24) {
            display += " (+)"
        }

        return display
    },
    calendarMonthViewTime(dateTime) {
        const dte = dayjs(dateTime)
        let disp = ""
        if (dte.minute() == 0)
            disp = dte.format("ha")
        else
            disp = dte.format("h:mma")
        return disp.slice(0, -1);
    }
}