import { createApp } from 'vue'
import App from './App.vue'
import router from './router/routes.js'
import '@mdi/font/css/materialdesignicons.css'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

import Datepicker from '@vuepic/vue-datepicker'; // from https://madewithvuejs.com/vue-3-datepicker
import '@vuepic/vue-datepicker/dist/main.css';

const vuetify = createVuetify({  
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    }
  },
  components,
  directives,
})

createApp(App).use(router).use(vuetify).component('Datepicker', Datepicker).mount('#app')
