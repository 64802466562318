import { createWebHistory, createRouter } from "vue-router";
import VolunteerLogin from "@/views/VolunteerLogin.vue";
import VolunteerAuth from "@/views/VolunteerAuth.vue";
import About from "@/views/AboutPage.vue";
import NotFound from "@/views/NotFound.vue";
import Events from "@/views/EventsList.vue";
import Schedule from "@/views/EventSchedule.vue";
import FailedLogin from "@/views/FailedLogin.vue";
import SignUp from "@/views/ShiftSignup.vue";

const routes = [
  {
    path: "/",
    name: "Login",
    component: VolunteerLogin,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
  {
    path: "/login/:token",
    name: "Auth",
    component: VolunteerAuth,
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
  {
    path: "/events",
    name: "Events List",
    component: Events,
    meta: {
      nav: 'events',
      loggedIn: true
    }
  },
  {
    path: "/schedule/:eventId",
    name: "Schedule",
    component: Schedule,
    meta: {
      nav: 'schedule',
      loggedIn: true,
      eventSpecific: true
    }
  },
  {
    path: "/signup/:eventId",
    name: "Sign Up",
    component: SignUp,
    meta: {
      nav: 'signup',
      loggedIn: true,
      eventSpecific: true
    }
  },
  {
    path: "/login-failed",
    name: "LoginFailed",
    component: FailedLogin,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;