<script setup>
import { ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import axios from 'axios'
// Override token for development
const devToken = process.env.VUE_APP_DEV_LOGIN_TOKEN
const auth = ref(false)
const route = useRoute();
const router = useRouter()

async function authenticate() {
  // Check if dev token exists, otherwise get token from URL
  const token =  devToken || route.params.token
  let url = "/api/auth/" + token
  try {
    var res = await axios.post(url)
    auth.value = res.data
    if (auth.value == true) {
      router.push('/events')
    }
    else {
      router.push("/login-failed")
    }
  }
  catch (ex) {
    console.log(ex)
    router.push("/login-failed")
  }
}

authenticate()

</script>


<template>
  <v-alert type="info" text="Logging you in..." />
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
