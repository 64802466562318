<script setup>
import { defineProps } from 'vue';
import { useRouter } from 'vue-router';
import { store } from '@/store/store';
import { dateUtils } from '@/util/dateUtil'
defineProps(['event'])
const router = useRouter();

function goToEvent(evt) {
    store.currentEventName = evt.Name
    router.push({ name: 'Schedule', params: { eventId: evt.EventId } })
}
</script>

<template>
    <v-col cols="12" sm="12" md="6" xl="4">
        <v-card @click="goToEvent(event)" class="event-item">
            <v-card-title>{{ event.Name }}</v-card-title>
            <v-card-text>
                <div>{{ event.OrgName }}</div>
                <div>{{ dateUtils.startEndDisplay(event.StartDate, event.EndDate) }}</div>
            </v-card-text>
        </v-card>
    </v-col>
</template>

<style scoped>
.event-item {
    margin-bottom: 10px;
    margin-top: 5px;
    border: 1px solid grey;
    padding: 1px;
    border-radius: 10px;
    color: white;
    font-weight: bold;
    background-color: #486574;
}

.event-name {
    font-size: 1.4em;
}
</style>