<script setup>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { store } from '@/store/store';

import axios from 'axios';
import TextEventScheduleDay from '@/components/TextEventScheduleDay.vue'

const dayjs = require('dayjs')

const route = useRoute()
const router = useRouter()
const eventId = route.params.eventId
let status = ref('loading')
let data = ref({
    Event: {},
    Schedule: [],
    Days: []
})
let hasAnyShifts = ref(false)

function goToSignup() {
    router.push("/signup/" + eventId)
}

async function getSchedule() {
    let url = "/api/schedule/" + eventId
    try {
        let res = await axios.get(url)
        store.currentEventName = res.data.Event.Name
        store.currentEventStart = res.data.Event.StartDate
        store.currentEventEnd = res.data.Event.EndDate
        data.value.Event = res.data.Event
        data.value.Schedule = res.data.Schedule

        if (res.data.Schedule.length > 0) {
            hasAnyShifts.value = true
            data.value.Schedule.forEach(shift => {
                let shiftStartDate = dayjs(shift.Start)
                let shiftEndDate = dayjs(shift.End)
                let timeUntilShift = shiftStartDate - dayjs()
                if (timeUntilShift > 0) {
                    shift.timing = "future"
                } else {
                    if (dayjs() - shiftEndDate > 0) {
                        shift.timing = "past"
                    } else {
                        shift.timing = "now"
                    }
                }

                shift.credits = shift.HoursWorked * shift.Weight

                let day = data.value.Days.find(d => d.Date == shiftStartDate.format("MM/DD/YYYY"))
                if (!day) {
                    let Timing = ""
                    if (shiftStartDate.format("MM/DD/YYYY") == dayjs().format("MM/DD/YYYY")) {
                        Timing = "today"
                    } else {
                        if (timeUntilShift > 0) {
                            Timing = "future"
                        } else {
                            Timing = "past"
                        }
                    }
                    data.value.Days.push({
                        Date: dayjs(shift.Start).format("MM/DD/YYYY"),
                        Shifts: [shift],
                        Timing
                    })
                } else {
                    day.Shifts.push(shift)
                }
            })
        }

        data.value.Days.forEach(day => {
            day.Shifts = day.Shifts.sort((a, b) => dayjs(a.Start) - dayjs(b.Start))
        })

        status.value = 'loaded'
    }
    catch (ex) {
        if (ex.response && ex.response.status == 401)
            router.push('/')
        else
            console.log(ex)
    }
}
getSchedule()

const upcomingDays = computed(() => {
    return data.value.Days.filter(x => x.Timing == "future" || x.Timing == "today").sort((a, b) => dayjs(a.Date) - dayjs(b.Date))
})

const pastDays = computed(() => {
    return data.value.Days.filter(x => x.Timing == "past").sort((a, b) => dayjs(b.Date) - dayjs(a.Date))
})

const credits = computed(() => {
    let res = {
        worked: 0,
        signedUp: 0,
        pending: 0,
        total: 0
    }
    data.value.Schedule.forEach(s => {
        switch (s.Status) {
            case "ok":
                res.worked += s.credits
                break
            case "warn":
            case "danger":
                break
            default:
                if (s.timing == "past") {
                    res.pending += s.credits
                } else {
                    res.signedUp += s.credits
                }
                break
        }
    })
    res.total = res.worked + res.signedUp + res.pending
    return res
})

const eventIsOver = computed(() => {
    return dayjs(data.value.Event.EndDate) < dayjs()
})

const showSignUpBtn = computed(() => {
    return (status.value == 'loaded' && !eventIsOver.value)
})
</script>

<template>
    <v-row v-if="credits.total > 0">
        <v-col cols="12" md="6" class="offset-md-3">
            <v-expansion-panels>
                <v-expansion-panel >
                    <v-expansion-panel-title expand-icon="mdi-information-outline" class="">
                        Potential Credits: {{ credits.total }}
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                        <div>Credits You've Earned: {{ credits.worked }}</div>
                        <div v-if="credits.pending > 0">Credits Pending Approval: {{ credits.pending }}</div>
                        <div>Credits You've Signed Up For: {{ credits.signedUp }}</div>
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>
    <v-row v-if="showSignUpBtn">
        <v-col>
            <v-btn @click="goToSignup" color="primary">Sign up for shifts</v-btn>
        </v-col>
    </v-row>
    <v-row v-if="upcomingDays.length > 0">
        <v-col>
            <h3>Upcoming Shifts:</h3>
        </v-col>
    </v-row>
    <v-row v-for="item in upcomingDays" :key="item">
        <TextEventScheduleDay :item="item" />
    </v-row>
    <v-row v-if="pastDays.length > 0">
        <v-col>
            <h3>Past Shifts:</h3>
        </v-col>
    </v-row>
    <v-row v-for="item in pastDays" :key="item">
        <TextEventScheduleDay :item="item" />
    </v-row>
    <v-row>
        <v-alert type="info" v-show="!hasAnyShifts && status == 'loaded'">
            {{ eventIsOver ? "You did not sign up for any shifts during this event." :
        "You have not yet signed up for any shifts for this event." }}
        </v-alert>
        <v-alert type="info" v-show="status == 'loading'">Fetching data.</v-alert>
    </v-row>
</template>

<style scoped>
.show-past {
    cursor: pointer;
}
</style>