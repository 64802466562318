<script setup>
import { ref, defineProps, watch } from 'vue';
import { useRouter } from 'vue-router';
import { dateUtils } from '@/util/dateUtil';
import axios from 'axios'

import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import resourceTimegridPlugin from '@fullcalendar/resource-timegrid'
import resourceDaygridPlugin from '@fullcalendar/resource-daygrid'
import listPlugin from '@fullcalendar/list'
import adaptivePlugin from '@fullcalendar/adaptive'
import dayjs from 'dayjs';

// check out https://github.com/fullcalendar/fullcalendar

const _ = require("lodash");
const router = useRouter()
const props = defineProps({
    data: Object
})
let showDialog = ref(false)
let selectedShift = ref("")

watch(props, () => {
    generateCalendarEvents()
    let calApi = calendar.value.getApi()
    calApi.getEvents().forEach(evt => evt.remove())
    calendarOptions.events.forEach(evt => calApi.addEvent(evt))
})

let doingSomething = ref(false)
const calendar = ref(null)

const calendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin, resourceTimelinePlugin, resourceTimegridPlugin, resourceDaygridPlugin, listPlugin, adaptivePlugin],
    initialView: 'listYear',
    weekends: true,
    events: [],
    rerenderDelay: 2,
    schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
    navLinks: true,
    contentHeight: "auto",
    // aspectRatio: 1,
    // eslint-disable-next-line no-unused-vars
    navLinkDayClick: navLinkDateClick,
    eventClick: eventClick,
    firstDay: 1,
    headerToolbar:
    {
        left: 'listYear,dayGridMonth,resourceTimeGridDay,timelineDay',
        center: 'title',
        right: 'prev,next'
    },
    views: {
        listYear: {
            buttonText: isSmallViewport() ? "L" : "List"
        },
        dayGridMonth: {
            buttonText: isSmallViewport() ? "M" : "Month"
        },
        resourceTimeGridDay: {
            buttonText: isSmallViewport() ? "D" : "By Dept"
        },
        timelineDay: {
            buttonText: isSmallViewport() ? "T" : "Timeline"
        },
    }
}

function navLinkDateClick(date) {
    // get all shifts that are on the day being switched to so the time can be set correctly
    let dayShifts = props.data.Shifts.filter(x => dayjs(x.Start) > dayjs(date) && dayjs(x.Start) < dayjs(date).add(1, 'day'))
    let earliest = _.orderBy(dayShifts, ['Start'], ['asc'])[0]

    let calApi = calendar.value.getApi()
    calApi.changeView("timelineDay")
    calApi.gotoDate(date)
    calApi.scrollToTime(dayjs(earliest.Start).subtract(30, 'minute').format("hh:mm"))
}

// an event in the calendar was clicked on
function eventClick(evt) {
    const pos = evt.event.extendedProps
    selectedShift.value = Object.assign({}, pos, getRelevantShiftDataForPos(pos.id), { "evt": evt })
    showDialog.value = true
}

function closeEventModal() {
    showDialog.value = false
}

// an event action was initiated
async function eventAction(evt) {
    const pos = selectedShift.value

    if (!doingSomething.value && !pos.alreadyBooked && (pos.canSignup || pos.canRemove)) {
        doingSomething.value = true
        let res = null
        if (pos.canSignup) {
            res = await volunteerAction(pos, "add")
        }
        else if (pos.canRemove) {
            res = await volunteerAction(pos, "remove")
        }
        if (res.Success == true) {
            let pos = props.data.Positions.filter(x => x.Id == res.ShiftPositionData.Id)[0]

            // update data with return values - need this since the 'already booked' calculation runs off of the in-mem position data
            pos.Status = res.ShiftPositionData.Status
            pos.CurrentSignups = res.ShiftPositionData.CurrentSignups
            pos.SignedUpNames = res.ShiftPositionData.SignedUpNames

            // update in-memory event information in calendar
            updateEventAfterAction(evt.event, res)
            updateAllAfterAction()
        }
        closeEventModal()
    }
}

// take the appropriate action for the event clicked on
async function volunteerAction(pos, action) {
    let url = "/api/schedule/signup/" + pos.id + "/" + action
    try {
        let res = await axios.post(url)
        let data = res.data
        if (data.Success == false) {
            alert('Something happened, and that did not work. You should never see this, if you do please log out, back in, and try again. If it still shows up, let your lead know.')
            // errorText.value = data.Message
            // snackbar.value = false
            // snackbar.value = true
        }
        doingSomething.value = false
        return data
    }
    catch (ex) {
        if (ex.response && ex.response.status == 401)
            router.push('/')
        else
            console.log(ex)
    }
}

function generateCalendarEvents() {
    let events = []
    let resources = []
    props.data.Positions.forEach(pos => {
        // TODO: map/reduce this better so we don't need to re-find shift and dept all the time
        const shift = props.data.Shifts.filter(y => y.ShiftId == pos.ShiftId)[0]
        const dept = props.data.Departments.filter(y => y.ShiftScheduleId == shift.ShiftScheduleId)[0]
        const inSignupRange = (dept.CutoffDate == null || dayjs(dept.CutoffDate) > dayjs()) && (dept.OpenDate == null || dayjs(dept.OpenDate) < dayjs())
        const evt = {
            "title": pos.Name,
            "start": shift.Start,
            "end": shift.End,
            "icon": "asterisk",
            "resourceId": dept.Department,
            "extendedProps": {
                "dept": dept.Department,
                "id": pos.Id,
                "deptId": dept.ShiftScheduleId,
                "status": pos.Status,
                "canSignup": pos.Status == "Available" && dept.AllowSelfSignup && inSignupRange,
                "canRemove": pos.Status == "You" && dept.AllowSelfRemoval,
                "weight": shift.Weight,
                "currentSignups": pos.CurrentSignups,
                "maxSignups": pos.MaxSignups
            },
            classNames: ['test'],
            // borderColor:
            // textColor
        }
        evt.extendedProps.alreadyBooked = getAlreadyBooked(evt)
        evt.backgroundColor = getBackgroundColor(evt)
        evt.classNames = ["cal-event-clickable"]
        events.push(evt)
    })
    props.data.Departments.forEach(dept => {
        resources.push({
            id: dept.Department,
            name: dept.Department
        })
    })
    calendarOptions.events = events
    calendarOptions.resources = resources
}

function getRelevantShiftDataForPos(posId) {
    const pos = props.data.Positions.filter(x => x.Id == posId)[0]
    const shift = props.data.Shifts.filter(y => y.ShiftId == pos.ShiftId)[0]
    // const dept = props.data.Departments.filter(y => y.ShiftScheduleId == shift.ShiftScheduleId)[0]

    return {
        "start": shift.Start,
        "end": shift.End,
        "pos": pos.Name,
        "signedUpNames": pos.SignedUpNames,
        "shiftNote": shift.Notes,
        "posNote": pos.Notes
    }
}

// returns true or false if there is already a postion with status 'You' anywhere in the timeframe
function getAlreadyBooked(evt) {
    // if the shift in question is the volunteer, don't do 'overlap' calcs
    if (evt.extendedProps.status == "You")
        return false

    const shiftIds = props.data.Positions.filter(x => x.Status == "You").map(x => x.ShiftId)
    const myShifts = props.data.Shifts.filter(x => shiftIds.indexOf(x.ShiftId) != -1).map(x => ({
        "start": dayjs(x.Start),
        "end": dayjs(x.End)
    }))
    const start = dayjs(evt.start)
    const end = dayjs(evt.end)

    // determine if overlap
    // Allen's Interval Algebra -- https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
    return myShifts.filter(x => x.end > start && x.start < end).length > 0
}

// an action has been taken; update the calendar event with the correct data
function updateEventAfterAction(evt, resData) {
    let posData = resData.ShiftPositionData
    let dept = props.data.Departments.filter(y => y.ShiftScheduleId == evt.extendedProps.deptId)[0]

    evt.setExtendedProp("status", posData.Status)
    evt.setExtendedProp("canSignup", posData.Status == "Available" && dept.AllowSelfSignup)
    evt.setExtendedProp("canRemove", posData.Status == "You" && dept.AllowSelfRemoval)
    evt.setProp("backgroundColor", getBackgroundColor(evt))
}

// iterate over all items in the events grid, updating as needed after an action is taken
function updateAllAfterAction() {
    let calApi = calendar.value.getApi()
    calApi.getEvents().forEach(evt => {
        evt.setExtendedProp("alreadyBooked", getAlreadyBooked(evt))
        evt.setProp("backgroundColor", getBackgroundColor(evt))
    })
}

function getBackgroundColor(evt) {
    let color = "#B71C1C"
    if (evt.extendedProps.alreadyBooked == true) {
        color = "grey"
    }
    else if (evt.extendedProps.status == "You") {
        color = "#01579B"
    }
    else if (evt.extendedProps.canSignup) {
        color = "#00695C"
    }
    return color;
}

function isSmallViewport() {
    return window.matchMedia("(max-width: 600px)").matches
}

// returns the icon name to use both in calendar view and in the modal when an event is clicked on
function getEventIcon(evt) {
    let iconName = ""
    if (evt.alreadyBooked && (evt.canRemove || evt.canSignup)) {
        iconName = "mdi-clock-alert-outline"
    }
    else if (evt.status == 'You' && evt.canRemove) {
        iconName = "mdi-account-minus"
    }
    else if (evt.status == 'You' && !evt.canRemove) {
        iconName = "mdi-account"
    }
    else if (!evt.canRemove && !evt.canSignup) {
        iconName = "mdi-cancel"
    }
    else if (evt.canSignup) {
        iconName = "mdi-account-plus"
    }

    return iconName
}

generateCalendarEvents()
</script>

<template>
    <v-row class="mt-0">
        <v-col class="pt-0 pb-0">
            <small>Select a shift to get more information.<br> Select a
                date to go to other views for that date.</small>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <FullCalendar ref="calendar" :options="calendarOptions">
                <template v-slot:eventContent="arg">
                    <span v-if="arg.view.type != 'dayGridMonth'">
                        <v-icon :icon="getEventIcon(arg.event.extendedProps)" size="large"
                            :color="arg.view.type == 'listYear' ? getBackgroundColor(arg.event) : 'white'"
                            style="z-index: 0;"></v-icon>
                        &nbsp;
                    </span>

                    <div v-if="arg.view.type == 'dayGridMonth'" class="fc-daygrid-event-dot" :style="{ 'border-color': getBackgroundColor(arg.event) }"></div>
                    <span v-if="arg.view.type == 'dayGridMonth'">
                        
                        {{ dateUtils.calendarMonthViewTime(arg.event.start) }}
                        &nbsp;
                    </span>
                    <span style="overflow: hidden" >
                        <b v-if="arg.view.type.indexOf('resource') == -1">

                            {{ arg.view.type == "dayGridMonth" ? arg.event.extendedProps.dept.substr(0, 3) + "..." :
                                arg.event.extendedProps.dept }}
                        </b>

                        {{ arg.event.title }}

                        <small v-if="arg.view.type != 'dayGridMonth'">
                            [{{ arg.event.extendedProps.currentSignups }} / {{ arg.event.extendedProps.maxSignups == null ?
                                "∞"
                                :
                                arg.event.extendedProps.maxSignups }}]</small>
                    </span>
                </template>
            </FullCalendar>
        </v-col>
    </v-row>
    <v-row>
        <v-col>
            <v-dialog transition="dialog-bottom-transition" width="auto" v-model="showDialog" min-width="350"
                max-width="500">
                <v-card>
                    <v-toolbar color="primary">
                        <template v-slot:title>
                            <strong>{{ selectedShift.dept }}</strong> - {{ selectedShift.pos }}
                        </template>
                    </v-toolbar>
                    <v-card-text>
                        <v-container>
                            <v-row v-if="selectedShift.alreadyBooked" style="padding-bottom: 20px;">
                                <v-alert type="warning">You are already signed up to work during this time period.
                                    If you would like to work this shift please remove yourself from the overlapping shift
                                    or talk to your lead.</v-alert>
                            </v-row>
                            <v-row v-if="selectedShift.currentSignups == selectedShift.maxSignups && !selectedShift.canRemove && !selectedShift.canSignup" style="padding-bottom: 20px;">
                                <v-alert type="warning">This shift is full.</v-alert>
                            </v-row>
                            <v-row class="embiggen">
                                {{ dayjs(selectedShift.start).format("MMM D, YYYY") }}
                            </v-row>
                            <v-row>
                                {{ dateUtils.shiftTime(selectedShift.start, selectedShift.end) }}
                            </v-row>
                            <v-row>
                                <small>{{ selectedShift.weight }} credit per hour worked</small>
                            </v-row>
                            <v-row v-if="selectedShift.shiftNote != null && selectedShift.shiftNote.length > 0"
                                style="padding-top:15px">
                                {{ selectedShift.shiftNote }}
                            </v-row>
                            <v-row v-if="selectedShift.posNote != null && selectedShift.posNote.length > 0">
                                {{ selectedShift.posNote }}
                            </v-row>


                            <v-row style="padding-top:15px">
                                Signups: {{ selectedShift.currentSignups }} / {{ selectedShift.maxSignups == null ? "∞" :
                                    selectedShift.maxSignups }}
                            </v-row>
                            <v-row v-if="selectedShift.signedUpNames != null">
                                <ul style="margin-left:30px; list-style: none;">
                                    <li v-for="name in selectedShift.signedUpNames" :key="name">
                                        <small>{{ name }}</small>
                                    </li>
                                </ul>
                            </v-row>

                        </v-container>
                    </v-card-text>
                    <v-card-actions>

                        <v-btn color="primary" v-if="selectedShift.canSignup && !selectedShift.alreadyBooked"
                            @click="eventAction(selectedShift.evt)"><v-icon :icon="getEventIcon(selectedShift)"></v-icon>
                            &nbsp;
                            Sign Up</v-btn>
                        <v-btn color="primary" v-if="selectedShift.canRemove"
                            @click="eventAction(selectedShift.evt)"><v-icon :icon="getEventIcon(selectedShift)"></v-icon>
                            &nbsp;
                            Remove Self</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="closeEventModal">Close</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<style>
.cal-event-clickable {
    cursor: pointer !important;
}

.embiggen {
    font-size: 1.5em;
}

.fc .fc-list-table tbody>tr:first-child th {
    z-index: 1;
}
</style>