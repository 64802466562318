<script setup>
import { ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import axios from 'axios'

import SignUpCalendar from '@/components/SignUpCalendar.vue'
const dayjs = require('dayjs')
const _ = require("lodash");

const route = useRoute()
const router = useRouter()
let data = ref(null)
let shiftsByDate = ref(null)
let loadedDept = ref(false)
let loadedShifts = ref(false)

function goMyShifts() {
    let eventId = route.path.substring(route.path.lastIndexOf("/") + 1)
    router.push("/schedule/" + eventId)
}

const departments = ref([    
    {
        value: -1,
        title: "My Departments and Open Departments",
        disabled: true
    },
    {
        value: 0,
        title: "Only My Departments",
        disabled: true
    }
])

const filter_start = ref(null)
const filter_end = ref(null)
// use 'all departments' as default value
const filter_deptIds = ref([departments.value[0]])

watch(filter_start, () => {
    getAvailableShifts()
})
watch(filter_end, () => {
    getAvailableShifts()
})

function onDeptChange(newDept) {
    // get the most recent one clicked
    const mostRecent = newDept.map( x=> x.value).slice(-1)[0];

        // if the most recent is 0 or -1, remove everything else in the array
    if (mostRecent <= 0) {
        console.log(filter_deptIds.value.filter(x => x.value == mostRecent))
        filter_deptIds.value = filter_deptIds.value.filter(x => x.value == mostRecent)
    } 
    // if it is an actual department, remove 'only my departments' and 'all departments'
    else {
        filter_deptIds.value = filter_deptIds.value.filter(x => x.value > 0)
    }
    getAvailableShifts()
}


async function getDepartmentList() {
    const eventId = route.path.substring(route.path.lastIndexOf("/") + 1)
    let url = "/api/schedule/departments/" + eventId
    try {
        let res = await axios.get(url)
        _.orderBy(res.data, 'Name').forEach(item => {
            departments.value.push({
                value: item.Id,
                title: item.Name,
                disabled: true
            });
        })
        loadedDept.value = true
    }
    catch (ex) {
        if (ex && ex.response && ex.response.status == 401)
            router.push('/')
        else
            console.log(ex)
    }
}

async function getAvailableShifts() {
    const eventId = route.path.substring(route.path.lastIndexOf("/") + 1)
    let url = "/api/schedule/signup/" + eventId
    try {
        let res = await axios.post(url, {
            start: filter_start.value,
            end: filter_end.value,
            // magic string : deptId -1 = 'all departments', deptId 0 = 'only my departments'
            departmentIds: filter_deptIds.value.map(x => x.value)
        });

        data.value = res.data
        shiftsByDate.value = calcShiftsBydate()
        loadedShifts.value = true
    }
    catch (ex) {
        if (ex && ex.response && ex.response.status == 401)
            router.push('/')
        else
            console.log(ex)
    }
}

getDepartmentList();
getAvailableShifts();

// puts all shift information into a format to be displayed by date, as text items
function calcShiftsBydate() {

    var shiftsWithDept = _.orderBy(data.value.Shifts, ['Start'], ['asc']).map(x => {
        x.Positions = data.value.Positions.filter(y => y.ShiftId == x.ShiftId)
        return x
    })

    let shiftsByDate = []
    let currDate = {
        Date: null,
        Shifts: []
    }

    shiftsWithDept.forEach(swd => {
        if (currDate.Date == null) {
            currDate.Date = dayjs(swd.Start).format("MM/DD/YYYY")
        }

        if (dayjs(swd.Start).format("MM/DD/YYYY") == currDate.Date) {
            currDate.Shifts.push(swd)
        }
        else {
            shiftsByDate.push(currDate)
            currDate = {
                Date: dayjs(swd.Start).format("MM/DD/YYYY"),
                Shifts: []
            }
            currDate.Shifts.push(swd)
        }
    })
    shiftsByDate.push(currDate)

    return shiftsByDate
}
</script>

<template>
    <v-row class="mt-0">
        <v-col>
            <v-btn @click="goMyShifts" prepend-icon="mdi-arrow-left">Back to event summary</v-btn>
        </v-col>
    </v-row>
    <v-row v-show="!(loadedDept && loadedShifts)">
        <v-col>
            <v-alert type="info" text="Fetching shift info..." />
        </v-col>
    </v-row>
    <v-row v-show="loadedDept && loadedShifts">
        <v-col cols="12" lg="8" class="offset-lg-2 pt-0 pb-0">
            <v-select v-model="filter_deptIds" :items="departments" label="Select departments to filter" multiple @update:modelValue="onDeptChange"
                density="compact" return-object>
            </v-select>
        </v-col>
    </v-row>
    <hr>
    <SignUpCalendar v-if="data" :data="data"></SignUpCalendar>
</template>

<style scoped>
.filter-condition {
    text-align: left;
}

.v3dp__datepicker {
    width: 100px;
    display: inline-flex;
}

.v3dp__input_wrapper input {
    border-style: solid !important;
    width: 100px !important;
}
</style>